import React, {useContext, useEffect, useState} from 'react';
import UserContext from "../../context/userContext";
import InteractiveSearch from "../../components/interactiveSearch";
import StandardSelectionBar from "./standardSelectionBar";
import CreateItemModal from "./createItemModal";
import ViewAssessmentItemsModal from "./viewAssessmentItemsModal";
import {useNavigate} from "react-router-dom";
import SupplementalStandardModal from "./supplementalStandardModal";
import TeacherNavBar from "../teacherNavBar";
import EditKnowShowChartModal from "./editKnowShowChartModal";
import AddRecallElementsModal from "./addRecallElementsModal";
import DOMPurify from "dompurify";
import AssignRecallModal from "./assignRecallModal";
import getWithAuth from "../../context/getWithAuth";
import Loader from "../../components/loader";
import RecallEditPromptModal from "./recallEditPromptModal";
import ToSModal from "../../components/tosModal";


function Planning() {
    const [standardSets, setStandardSets] = useState(null);
    const [rosters, setRosters] = useState(null);
    const [knowShowCharts, setKnowShowCharts] = useState(null);
    const [assessmentItems, setAssessmentItems] = useState(null);
    const navigate = useNavigate();


    const [selectedStandardSet, setSelectedStandardSet] = useState(null);
    const [selectedStandard, setSelectedStandard] = useState(null);
    const [searchValue, setSearchValue] = useState(null);
    const [createItemModal, setCreateItemModal] = useState(null);
    const [viewAssessmentItemsModal, setViewAssessmentItemsModal] = useState(null);
    const [supplementalStandardModal, setSupplementalStandardModal] = useState(null);
    const [editKnowShowChartModal, setEditKnowShowChartModal] = useState(null);
    const [addRecallElementsModal, setAddRecallElementsModal] = useState(null);
    const [recallEditPromptModal, setRecallEditPromptModal] = useState(null);
    const [assignRecallModal, setAssignRecallModal] = useState(null);

    const user = useContext(UserContext);

    useEffect(() => {
        if (createItemModal || viewAssessmentItemsModal || editKnowShowChartModal || addRecallElementsModal || recallEditPromptModal || assignRecallModal) {
        document.body.style.overflow = 'hidden';
    } else {
            document.body.style.overflow = 'unset';}
    }, [createItemModal, viewAssessmentItemsModal, editKnowShowChartModal, addRecallElementsModal, recallEditPromptModal, assignRecallModal])

    const fetchStandardSets = async () => {
        if (user.userContext) {
                try {
                    getWithAuth(`/planning/getStandardSets`, user.userAttributes['custom:school'], {preps: encodeURIComponent(JSON.stringify(user.userContext.preps))})
                        .then(async r => {
                            setStandardSets(r);
                            if (selectedStandardSet) {
                                let newStandardSet = r.find(item => (item.title === selectedStandardSet.title));
                                await setSelectedStandard(null);
                                await setSearchValue(null);
                                await setSelectedStandardSet(newStandardSet);
                            }
                })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchRosters = async () => {
        if (user.userContext) {
            try {
                getWithAuth(`/logistics/getRosters`, user.userAttributes['custom:school'], {userId: user.userContext._id.$oid})
                    .then(async r => {
                        setRosters(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchKnowShowCharts = async (user) => {
        if (user.userContext) {
            try {
                getWithAuth(`/planning/getKnowShow`, user.userAttributes['custom:school'], {preps: encodeURIComponent(JSON.stringify(user.userContext.preps))})
                    .then(async r => {
                        setKnowShowCharts(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchAssessmentItems = async (user) => {
        if (user.userContext) {
            try {
                getWithAuth(`/planning/getAssessmentItems`, user.userAttributes['custom:school'], {preps: encodeURIComponent(JSON.stringify(user.userContext.preps))})
                    .then(async r => {
                        setAssessmentItems(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const handleChangeStandardSet = async (event) => {
        let newStandardSet = standardSets.find(item => (item.title === event.target.value));
        await setSelectedStandard(null);
        await setSearchValue(null);
        await setSelectedStandardSet(newStandardSet);
    }

    const handleSearchChange = (e) => {
        if (selectedStandardSet) {
            setSearchValue(selectedStandardSet.standards.filter(standard => (standard.standardCode.toLowerCase().includes(e.target.value.toLowerCase()) || standard.title.toLowerCase().includes(e.target.value.toLowerCase()) || standard.text.toLowerCase().includes(e.target.value.toLowerCase()) || standard.supportingText.toLowerCase().includes(e.target.value.toLowerCase()))));
        } else {
            return null;
        }
    }

    useEffect(() => {
        fetchStandardSets();
        fetchRosters();
        fetchKnowShowCharts(user);
        fetchAssessmentItems(user);
    }, [user.userContext])

    const renderHtml = (htmlString) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);
        const plainText = new DOMParser().parseFromString(sanitizedHtml, 'text/html').body.textContent;
        return {
            __html: sanitizedHtml,
            plainText: plainText
        };
    };


    if (!user.userContext) {
        return <div className={'h-screen flex-col overflow-hidden'}>
            <TeacherNavBar/>
            <Loader message={'Fetching user data'}/>
        </div>
    } else if (!user.userContext.termsAccepted) {
        return <ToSModal user={user}/>
    } else if (user.userContext.role === 'student') {
        navigate('/home')
    } else if (!standardSets) {
        return <div className={'h-screen flex-col overflow-hidden'}>
            <TeacherNavBar />
            <Loader message={'Fetching relevant standard sets'}/>
        </div>
    } else {
        return (
            <div className={'h-screen flex-col overflow-hidden'}>
                <TeacherNavBar />
                <div className={"flex mb-2 mx-2"}>
                    <p className={"font-bold text-columbia_blue-800 text-xl"}>Standard Set: </p>

                    {(standardSets) ?
                        <>
                            <select className={"rounded px-2 ml-4 text-sm bg-columbia_blue-800 text-dutch_white-300 font-bold"}
                                    onChange={handleChangeStandardSet} defaultValue={""}>
                                <option disabled value={""}>Select a Standard Set</option>
                                {standardSets.filter(item => user.userContext.preps.includes(item.prepTitle)).sort((a, b) => a.prepTitle.toLowerCase().localeCompare(b.prepTitle.toLowerCase()))
                                    .map((standardSet) => <option key={standardSet.title}
                                                                  value={standardSet.title}>{standardSet.prepTitle}</option>)}

                            </select>

                            {(!selectedStandardSet) &&
                                <div className={"h-10 flex text-center"}
                                     style={{animation: 'bounce-horizontally 3s ease-in-out infinite'}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" width="20"
                                         height="30" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round" className="feather feather-arrow-left my-auto">
                                        <line x1="19" y1="12" x2="5" y2="12"></line>
                                        <polyline points="12 19 5 12 12 5"></polyline>
                                    </svg>
                                    <p className={"my-auto"}>Start here! Choose a standard set</p></div>}
                        </> : <div className={'px-24 bg-columbia_blue-800 rounded select-none flex items-center ml-12'} ><div className="border-t-2 border-b-2 border-burnt_sienna h-6 w-6 rounded-full animate-spin mx-auto my-auto"></div></div>
                    }

                </div>
                <div className={'flex h-[87%] w-screen'}>
                    {(selectedStandardSet && knowShowCharts) && <>
                        <div className={"flex mx-2 gap-4 w-full"}>
                            <section  role={'Standards'} tabIndex={0} className={"w-[40%] h-[90%] bg-columbia_blue-300 rounded border-columbia_blue-800 border-2 shadow-xl border-opacity-40"}>
                                <div className={"p-2 mx-1 flex justify-between border-b-2 border-columbia_blue-800"}>
                                    <p className={"font-bold text-xl text-columbia_blue-800"}>Standards: </p>
                                    <div className={"grow"}></div>

                                    <InteractiveSearch placeholder={"Search Standards"} handleChange={handleSearchChange}/>

                                </div>
                                <div className={"mx-1 h-[85%] overflow-y-scroll"}>
                                    {(searchValue) ? searchValue.map(standard => <StandardSelectionBar key={standard.standardCode} standard={standard} standardCode={standard.standardCode} selectedStandard={selectedStandard} setSelectedStandard={setSelectedStandard} knowShow={knowShowCharts.filter(item => (item.standard === standard.standardCode && item.prep === selectedStandardSet.prepTitle))}/>)
                                        : selectedStandardSet.standards.map(standard => <StandardSelectionBar key={standard.standardCode} standard={standard} standardCode={standard.standardCode} selectedStandard={selectedStandard} setSelectedStandard={setSelectedStandard} knowShow={knowShowCharts.filter(item => (item.standard === standard.standardCode && item.prep === selectedStandardSet.prepTitle))}/>)}
                                    <div className={'flex flex-col py-2 px-4 w-[80%] border-2 border-columbia_blue-800 my-2 bg-columbia_blue-800 hover:bg-columbia_blue-700 rounded border-opacity-30 mx-auto cursor-pointer shadow'}>
                                        <p className={'font-bold text-dutch_white-400'} onClick={() => setSupplementalStandardModal(true)}>Add Supplemental Standard</p>
                                    </div>
                                </div>
                            </section>
                            <div className={"w-full grow mx-1 h-[90%]"}>
                                {(selectedStandardSet && selectedStandard) ?
                                    // <StandardOverviewPanel selectedStandardSet={selectedStandardSet} standard={selectedStandard} standardCode={selectedStandard.standardCode} user={user} knowShow={knowShowCharts.filter(element => element.standard === selectedStandard.standardCode)} assessmentItems={assessmentItems.filter(element => element.standard === selectedStandard.standardCode)} setCreateItemModal={setCreateItemModal} setViewAssessmentItemsModal={setViewAssessmentItemsModal} resetFunction={fetchKnowShowCharts}/>
                                    <div className={'w-full flex flex-col h-full bg-dutch_white-100 border-2 border-columbia_blue-700 rounded'}>
                                        <p className={"text-xl font-bold text-columbia_blue-800 my-2"}>{selectedStandard.title}</p>
                                        <p className={'text-columbia_blue-800 mb-2 text-xs px-2'}>{selectedStandard.text}</p>
                                        <div className={'grid grid-cols-6 gap-2 h-full overflow-y-scroll p-2'}>
                                            <div className={'col-span-6'}>
                                                <p title={'Recall sets are for organizing the most fundamental information in a standard. These sets are uniquely built only for matching style questions.'} className={'border-b-2 font-bold text-xl text-columbia_blue-800'}>Recall</p>
                                                {/*{(knowShowCharts.filter(item => item.standard === selectedStandard.standardCode).length === 0) &&*/}
                                                {/*    <p className={'mt-4 text-columbia_blue-800 select-none'}>Looks like*/}
                                                {/*        this is standard has not been broken down yet! Click edit below*/}
                                                {/*        to get started. </p>}*/}

                                                {(knowShowCharts.filter(item => item.type === 'Recall' && item.active && item.standard === selectedStandard.standardCode && item.prep === selectedStandardSet.prepTitle).length === 0) ?
                                                    <p className={'mt-4 col-span-6 text-xs text-columbia_blue-800 select-none'}>There's
                                                        nothing here! Click Edit below to get to work on this
                                                        standard's Recall section!</p> :
                                                    <div className={'flex'}>
                                                        <div className={'flex flex-col w-1/2 p-2'}>
                                                            {knowShowCharts.filter(item => item.type === 'Recall' && item.active && item.standard === selectedStandard.standardCode && item.prep === selectedStandardSet.prepTitle).map((element, index) =>
                                                                (index % 2 === 0) &&
                                                                <div key={element._id.$oid}
                                                                     className={'bg-columbia_blue-300 col-span-3 rounded border-2 border-columbia_blue-700 px-2 flex flex-col shadow m-2'}>
                                                                    <div className={'flex flex-col h-full'}>
                                                                        <p className={'text-center font-bold p-1'}>{element.title}</p>
                                                                        <div
                                                                            className={'text-xs rounded-t bg-columbia_blue-500 p-1'}
                                                                            dangerouslySetInnerHTML={renderHtml(element.prompt)}/>
                                                                        <div
                                                                            className={'bg-paynes_gray h-full rounded-b flex flex-wrap p-2 mb-1'}>
                                                                            {(assessmentItems.find(item => item.knowShowId === element._id.$oid && item.status === 'active')) && assessmentItems.find(item => item.knowShowId === element._id.$oid && item.status === 'active').recallItems.map(item =>
                                                                                <div
                                                                                    title={renderHtml(item.match.content).plainText}
                                                                                    className={'bg-columbia_blue-400 text-xs m-1 p-1 h-fit rounded grow'}
                                                                                    dangerouslySetInnerHTML={renderHtml(item.prompt.content)}/>)}
                                                                        </div>
                                                                        <div className={'flex gap-2 mx-auto mb-1'}>
                                                                            <div
                                                                                className={'text-xs text-platinum-100 rounded bg-burnt_sienna-600 p-1 select-none border-2 border-platinum-500 border-opacity-50 hover:bg-burnt_sienna-700 cursor-pointer'}
                                                                                onClick={() => setAddRecallElementsModal(assessmentItems.find(item => item.knowShowId === element._id.$oid && item.status === 'active'))}>
                                                                                View / Add Items
                                                                            </div>
                                                                            <div
                                                                                className={'text-xs text-platinum-100 rounded bg-paynes_gray-700 p-1 select-none border-2 border-platinum-500 border-opacity-50 hover:bg-paynes_gray-800 cursor-pointer'}
                                                                                onClick={() => setRecallEditPromptModal({
                                                                                    oldPrompt: element.prompt,
                                                                                    assessmentItemId: element._id.$oid
                                                                                })}>
                                                                                Edit Prompt
                                                                            </div>

                                                                            {(assessmentItems.find(item => item.knowShowId === element._id.$oid && item.status === 'active')) && (assessmentItems.find(item => item.knowShowId === element._id.$oid && item.status === 'active').recallItems.length > 1) ?
                                                                                <div
                                                                                    className={'text-xs text-platinum-100 rounded bg-columbia_blue-800 p-1 select-none border-2 border-platinum-500 border-opacity-50 hover:bg-columbia_blue-900 cursor-pointer'}
                                                                                    onClick={() => setAssignRecallModal(assessmentItems.find(item => item.knowShowId === element._id.$oid))}>Assign
                                                                                    Items</div>
                                                                                : <div
                                                                                    className={'text-xs text-platinum-100 rounded bg-platinum-700 p-1 select-none border-2 border-platinum-500 border-opacity-50'}>Assign
                                                                                    Items</div>}
                                                                        </div>
                                                                    </div>
                                                                </div>)}
                                                        </div>

                                                        <div className={'flex flex-col w-1/2 p-2'}>
                                                            {knowShowCharts.filter(item => item.type === 'Recall' && item.active && item.standard === selectedStandard.standardCode && item.prep === selectedStandardSet.prepTitle).map((element, index) =>
                                                                (index % 2 === 1) &&
                                                                <div key={element._id.$oid}
                                                                     className={'bg-columbia_blue-300 col-span-3 rounded border-2 border-columbia_blue-700 px-2 flex flex-col shadow m-2'}>
                                                                    <div className={'flex flex-col h-full'}>
                                                                        <p className={'text-center font-bold p-1'}>{element.title}</p>
                                                                        <div
                                                                            className={'text-xs rounded-t bg-columbia_blue-500 p-1'}
                                                                            dangerouslySetInnerHTML={renderHtml(element.prompt)}/>
                                                                        <div
                                                                            className={'bg-paynes_gray h-full rounded-b flex flex-wrap p-2 mb-1'}>
                                                                            {(assessmentItems.find(item => item.knowShowId === element._id.$oid && item.status === 'active')) && assessmentItems.find(item => item.knowShowId === element._id.$oid && item.status === 'active').recallItems.map(item =>
                                                                                <div
                                                                                    title={renderHtml(item.match.content).plainText}
                                                                                    className={'bg-columbia_blue-400 text-xs m-1 p-1 h-fit rounded grow'}
                                                                                    dangerouslySetInnerHTML={renderHtml(item.prompt.content)}/>)}
                                                                        </div>
                                                                        <div className={'flex gap-2 mx-auto mb-1'}>
                                                                            <div
                                                                                className={'text-xs text-platinum-100 rounded bg-burnt_sienna-600 p-1 select-none border-2 border-platinum-500 border-opacity-50 hover:bg-burnt_sienna-700 cursor-pointer'}
                                                                                onClick={() => setAddRecallElementsModal(assessmentItems.find(item => item.knowShowId === element._id.$oid && item.status === 'active'))}>
                                                                                View / Add Items
                                                                            </div>
                                                                            <div
                                                                                className={'text-xs text-platinum-100 rounded bg-paynes_gray-700 p-1 select-none border-2 border-platinum-500 border-opacity-50 hover:bg-paynes_gray-800 cursor-pointer'}
                                                                                onClick={() => setRecallEditPromptModal({
                                                                                    oldPrompt: element.prompt,
                                                                                    assessmentItemId: element._id.$oid
                                                                                })}>
                                                                                Edit Prompt
                                                                            </div>

                                                                            {(assessmentItems.find(item => item.knowShowId === element._id.$oid && item.status === 'active')) && (assessmentItems.find(item => item.knowShowId === element._id.$oid && item.status === 'active').recallItems.length > 1) ?
                                                                                <div
                                                                                    className={'text-xs text-platinum-100 rounded bg-columbia_blue-800 p-1 select-none border-2 border-platinum-500 border-opacity-50 hover:bg-columbia_blue-900 cursor-pointer'}
                                                                                    onClick={() => setAssignRecallModal(assessmentItems.find(item => item.knowShowId === element._id.$oid))}>Assign
                                                                                    Items</div>
                                                                                : <div
                                                                                    className={'text-xs text-platinum-500 rounded bg-platinum-700 p-1 select-none border-2 border-platinum-500 border-opacity-50'}>Assign
                                                                                    Items</div>}
                                                                        </div>
                                                                    </div>
                                                                </div>)}
                                                        </div>

                                                    </div>
                                                }
                                            </div>
                                            <div className={'col-span-3'}>
                                                <p title={'Understanding elements are aimed toward complex information related to categorization or relationships among parts of a standard. These are for areas of knowledge that require more deliberate assessment and should take the form of declarative statements of fact.'}
                                                   className={'border-b-2 font-bold text-xl text-columbia_blue-800'}>Understand</p>
                                                {(knowShowCharts.filter(item => item.type === 'Know' && item.standard === selectedStandard.standardCode && item.active && item.prep === selectedStandardSet.prepTitle).length === 0) ?
                                                    <p className={'mt-4 text-xs col-span-6 text-columbia_blue-800 select-none'}>There's
                                                        nothing here! Click Edit below to get to work on this
                                                        standard's Understand section!</p> :
                                                    knowShowCharts.filter(item => item.type === 'Know' && item.active && item.standard === selectedStandard.standardCode && item.prep === selectedStandardSet.prepTitle).map(element =>
                                                        <div key={element._id.$oid}
                                                             className={'bg-columbia_blue-300 col-span-2 rounded border-2 border-columbia_blue-700 p-2 flex flex-col shadow m-2'}>
                                                            <div className={'flex flex-col h-full'}>
                                                                <p className={'text-left text-sm p-1'}>{element.text}</p>
                                                                <div className={'grow'}/>
                                                                <div className={'flex gap-2 mx-auto'}>
                                                                    <div
                                                                        className={'text-xs text-platinum-500 rounded bg-burnt_sienna-600 p-2 select-none border-2 border-platinum-500 border-opacity-50 hover:bg-burnt_sienna-700 cursor-pointer'}
                                                                        onClick={() => setCreateItemModal(element)}>Create
                                                                        Item
                                                                    </div>
                                                                    {(assessmentItems && assessmentItems.filter(item => item.knowShowId === element._id.$oid && item.status === 'active').length > 0) ?
                                                                        <div
                                                                            className={'text-xs text-platinum-100 rounded bg-columbia_blue-800 p-2 select-none border-2 border-platinum-500 border-opacity-50 hover:bg-columbia_blue-700 cursor-pointer'}
                                                                            onClick={() => setViewAssessmentItemsModal(assessmentItems.filter(item => item.knowShowId === element._id.$oid).filter(item => item.status === 'active'))}>View
                                                                            Items: {assessmentItems.filter(item => item.knowShowId === element._id.$oid && item.status === 'active').length}</div>
                                                                        : <div
                                                                            className={'text-xs text-platinum-100 rounded bg-platinum-700 p-2 select-none border-2 border-platinum-500 border-opacity-50'}>Assign
                                                                            Items</div>}
                                                                </div>
                                                            </div>
                                                        </div>)}

                                            </div>
                                            <div className={'col-span-3'}>
                                                <p title={'Apply elements represent the skills that students learn throughout a standard and demonstrate through assessment items. These should take the form of "I can..." statements.'}
                                                   className={'border-b-2 font-bold text-xl text-columbia_blue-800'}>Apply</p>
                                                {(knowShowCharts.filter(item => item.type === 'Show' && item.active && item.standard === selectedStandard.standardCode && item.prep === selectedStandardSet.prepTitle).length === 0) ?
                                                    <p className={'mt-4 text-xs col-span-6 text-columbia_blue-800 select-none'}>There's
                                                        nothing here! Click Edit below to get to work on this
                                                        standard's Understand section!</p> :
                                                    knowShowCharts.filter(item => item.type === 'Show' && item.active && item.standard === selectedStandard.standardCode && item.prep === selectedStandardSet.prepTitle).map(element =>
                                                        <div key={element._id.$oid}
                                                             className={'bg-columbia_blue-300 col-span-2 rounded border-2 border-columbia_blue-700 p-2 flex flex-col shadow m-2'}>
                                                            <div className={'flex flex-col h-full'}>
                                                                <p className={'text-left text-sm p-1'}>{element.text}</p>
                                                                <div className={'grow'}/>
                                                                <div className={'flex gap-2 mx-auto'}>
                                                                    <div
                                                                        className={'text-xs text-platinum-100 rounded bg-burnt_sienna-600 p-2 select-none border-2 border-platinum-500 border-opacity-50 hover:bg-burnt_sienna-700 cursor-pointer'}
                                                                        onClick={() => setCreateItemModal({...element, questionType: 'Traditional MC'})}>Create
                                                                        Item
                                                                    </div>
                                                                    {(assessmentItems && assessmentItems.filter(item => item.knowShowId === element._id.$oid && item.status === 'active').length > 0) ?
                                                                        <div
                                                                            className={'text-xs text-platinum-100 rounded bg-columbia_blue-800 p-2 select-none border-2 border-platinum-500 border-opacity-50 hover:bg-columbia_blue-700 cursor-pointer'}
                                                                            onClick={() => setViewAssessmentItemsModal(assessmentItems.filter(item => item.knowShowId === element._id.$oid).filter(item => item.status === 'active'))}>View
                                                                            Items: {assessmentItems.filter(item => item.knowShowId === element._id.$oid && item.status === 'active').length}</div>
                                                                        : <div
                                                                            className={'text-xs text-platinum-100 rounded bg-platinum-700 p-2 select-none border-2 border-platinum-500 border-opacity-50'}>Assign
                                                                            Items</div>}
                                                                </div>
                                                            </div>
                                                        </div>)}

                                            </div>
                                        </div>


                                        <div className={'grow'}></div>
                                        <div onClick={() => setEditKnowShowChartModal(selectedStandard)}
                                             className={'w-full bg-paynes_gray-500 hover:bg-paynes_gray-400 shadow-inner mt-1 py-1 text-dutch_white-200 select-none cursor-pointer'}>Edit Standard Elements
                                        </div>
                                    </div>

                                    : (selectedStandardSet) ?
                                        <div className={"h-full flex animate-bounce text-columbia_blue-700"}
                                             style={{animation: 'bounce-horizontally 1s ease-in-out infinite'}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                                 width="200" height="300" stroke="currentColor" strokeWidth="2"
                                                 strokeLinecap="round" strokeLinejoin="round"
                                                 className="feather feather-arrow-left my-auto">
                                                <line x1="19" y1="12" x2="5" y2="12"></line>
                                                <polyline points="12 19 5 12 12 5"></polyline>
                                            </svg>
                                            <p className={"my-auto text-4xl"}>Select a Standard</p>
                                        </div> : <></>}
                            </div>
                            <div className={'grow'}/>

                        </div>
                    </>}
                </div>
                {(createItemModal) && <CreateItemModal user={user} createItemModal={createItemModal}
                                                       setCreateItemModal={setCreateItemModal}
                                                       assessmentItems={assessmentItems}
                                                       setAssessmentItems={setAssessmentItems}
                                                       resetFunction={fetchAssessmentItems}/>}
                {(viewAssessmentItemsModal) && <ViewAssessmentItemsModal user={user} rosters={rosters}
                                                                         viewAssessmentItemsModal={viewAssessmentItemsModal}
                                                                         setViewAssessmentItemsModal={setViewAssessmentItemsModal}
                                                                         resetFunction={fetchAssessmentItems}/>}
                {(assignRecallModal) && <AssignRecallModal user={user} assignRecallModal={assignRecallModal}
                                                           setAssignRecallModal={setAssignRecallModal}
                                                           rosters={rosters}/>}
                {(supplementalStandardModal) &&
                    <SupplementalStandardModal setSupplementalStandardModal={setSupplementalStandardModal}
                                               school={user.userAttributes['custom:school']}
                                               standardSet={selectedStandardSet} resetFunction={fetchStandardSets}/>}
                {(editKnowShowChartModal) && <EditKnowShowChartModal editKnowShowChartModal={editKnowShowChartModal}
                                                                     selectedStandardSet={selectedStandardSet}
                                                                     setKnowShowCharts={setKnowShowCharts}
                                                                     setAssessmentItems={setAssessmentItems}
                                                                     setEditKnowShowChartModal={setEditKnowShowChartModal}
                                                                     user={user}
                                                                     standardCode={selectedStandard.standardCode}
                                                                     knowShowElements={knowShowCharts.filter(item => item.standard === selectedStandard.standardCode && item.prep === selectedStandardSet.prepTitle)}/>}
                {(addRecallElementsModal) &&
                    <AddRecallElementsModal user={user} addRecallElementsModal={addRecallElementsModal} setAddRecallElementsModal={setAddRecallElementsModal} assessmentItems={assessmentItems} setAssessmentItems={setAssessmentItems} resetFunction={fetchAssessmentItems}/>}
                {(recallEditPromptModal) && <RecallEditPromptModal setRecallEditPromptModal={setRecallEditPromptModal} recallEditPromptModal={recallEditPromptModal} user={user} getAssessmentItems={fetchAssessmentItems} />}
            </div>
        );
    }}

export default Planning;