import React, {useEffect, useState} from 'react';

function SeatingChart({ groups, groupModal }) {

    const [isLoading, setIsLoading] = useState(false);

    const colors = ['#e48a66', '#538ea5', '#ece0c0', '#1c2f37']
    const textColors = ['#1c2f37', '#ece0c0', '#1c2f37', '#ece0c0']

    return (
        <>
            <div
                className={"bg-columbia_blue-900 p-2 text-black cursor-black rounded-b rounded-tr overflow-y-auto shadow-xl"}>
                <div className={"bg-dutch_white-300 text-lg text-columbia_blue-900 p-2 rounded shadow flex"}>
                    <div className={'grow'}/>
                    <div>
                        <p className={'font-bold'}>{groupModal.title}</p>
                        <p className={'text-xs'}>{groupModal.prep}</p>
                    </div>
                    <div className={'grow'}/>
                </div>

                <div className={"grid grid-cols-4 my-2 gap-2"}>
                    {groups && groups.map((group, groupIndex) =>
                        <div className={"h-full flex flex-col bg-platinum-300 rounded p-1"}>
                            <p className={'font-bold'}>Group {groupIndex + 1}</p>
                            {group.map((student, index) =>
                                    <div
                                        id={`${groupIndex}-${index}`}
                                        className={'p-1 rounded mb-1 bg-burnt_sienna text-dutch_white-700 shadow grow flex flex-col'}
                                        style={{background: colors[index % 4], color: textColors[index % 4]}}>
                                        <div id={`${groupIndex}-${index}`} className={'grow'}/>
                                        <p id={`${groupIndex}-${index}`}
                                           className={''}>{student.firstName} {student.lastName}</p>
                                        <div id={`${groupIndex}-${index}`} className={'grow'}/>

                                    </div>)}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default SeatingChart;