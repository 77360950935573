import React, {useContext, useEffect, useState} from 'react';
import UserContext from "../../context/userContext";
import EditRosterModal from "./editRosterModal";
import {useNavigate} from "react-router-dom";
import NewRosterModal from "./newRosterModal";
import GroupModal from "./groupModal";
import CreateUnitModal from "./createUnitModal";
import DOMPurify from "dompurify";
import ChangeUnitModal from "./changeUnitModal";
import ViewAssessmentItemsModal from "../planning/viewAssessmentItemsModal";
import AdjustUnitModal from "./adjustUnitModal";
import getWithAuth from "../../context/getWithAuth";
import Loader from "../../components/loader";
import ToSModal from "../../components/tosModal";
import AssignRecallModal from "../planning/assignRecallModal";
import RosterAssignmentData from "./rosterAssignmentData";
import StudentDataModal from "./studentDataModal";

function Logistics() {

    const [rosters, setRosters] = useState(null);
    const [studentContext, setStudentContext] = useState(null);
    const [standardSets, setStandardSets] = useState(null);
    const [knowShowCharts, setKnowShowCharts] = useState(null);
    const [assessmentItems, setAssessmentItems] = useState(null);
    const [editRosterModal, setEditRosterModal] = useState(null);
    const [newRosterModal, setNewRosterModal] = useState(null);
    const [groupData, setGroupData] = useState(null);
    const [groupModal, setGroupModal] = useState(null);
    const [createUnitModal, setCreateUnitModal] = useState(null);
    const [unitPlans, setUnitPlans] = useState([]);
    const [changeUnitModal, setChangeUnitModal] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [viewAssessmentItemsModal, setViewAssessmentItemsModal] = useState(null);
    const [adjustUnitModal, setAdjustUnitModal] = useState(null);
    const [assignRecallModal, setAssignRecallModal] = useState(null);
    const [studentDataModal, setStudentDataModal] = useState(null);

    const user = useContext(UserContext);
    const navigate = useNavigate()

    useEffect(() => {
        if (editRosterModal || newRosterModal || groupModal || createUnitModal || changeUnitModal || viewAssessmentItemsModal || adjustUnitModal || assignRecallModal || studentDataModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';}
    }, [editRosterModal, newRosterModal, groupModal, createUnitModal, changeUnitModal, viewAssessmentItemsModal, adjustUnitModal, assignRecallModal, studentDataModal])

    const fetchRosters = async () => {
        if (user.userAttributes) {
            try {
                getWithAuth(`/logistics/getRosters`, user.userAttributes['custom:school'], {userId: user.userContext._id.$oid})
                    .then(async r => {
                        setRosters(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchStandardSets = async () => {
        if (user.userAttributes) {
            try {
                getWithAuth(`/logistics/getStandardSets`, user.userAttributes['custom:school'], {preps: encodeURIComponent(JSON.stringify(user.userContext.preps))})
                    .then(async r => {
                        setStandardSets(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchStudentContext = async () => {
        if (user.userAttributes) {
            try {
                getWithAuth(`/logistics/getStudentContext`, user.userAttributes['custom:school'], {preps: encodeURIComponent(JSON.stringify(user.userContext.preps))})
                    .then(async r => {
                        setStudentContext(r)
                    })
                } catch (error) {
                    console.error('Error fetching standards data:', error)
                }
        }
    }

    const fetchGroupData = async (idArr) => {
        try {
            getWithAuth(`/logistics/getGroupData`, user.userAttributes['custom:school'], {students: encodeURIComponent(JSON.stringify(idArr))})
                .then(async r => {
                    setGroupData(r)
                })
        } catch (error) {
            console.error('Error fetching standards data:', error)
        }

    }

    const fetchKnowShowCharts = async (user) => {
        if (user.userAttributes) {
            try {
                getWithAuth(`/planning/getKnowShow`, user.userAttributes['custom:school'], {preps: encodeURIComponent(JSON.stringify(user.userContext.preps))})
                    .then(async r => {
                        setKnowShowCharts(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchAssessmentItems = async (user) => {
        if (user.userContext) {
            try {
                getWithAuth(`/planning/getAssessmentItems`, user.userAttributes['custom:school'], {preps: encodeURIComponent(JSON.stringify(user.userContext.preps))})
                    .then(async r => {
                        setAssessmentItems(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const getUnitPlans = async (user) => {
        if (user.userAttributes) {
            try {
                getWithAuth(`/logistics/getUnitPlans`, user.userAttributes['custom:school'], {preps: encodeURIComponent(JSON.stringify(user.userContext.preps))})
                    .then(async r => {
                        setUnitPlans(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    useEffect(() => {
        fetchRosters();
        fetchStudentContext();
        fetchStandardSets();
        fetchAssessmentItems(user);
        fetchKnowShowCharts(user);
        getUnitPlans(user);
    }, [user.userContext]);

    useEffect( () => {
        if (rosters) {
            let idArr = rosters.flatMap(item => item.students)
            fetchGroupData(idArr)
        }
    }, [rosters])

    const renderHtml = (htmlString) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);
        return { __html: sanitizedHtml };
    };

    const handleAssignmentClick = (knowShow) => {
        if (knowShow.type === "Recall") {
            setAssignRecallModal(assessmentItems.find(item => item.knowShowId === knowShow._id.$oid))
        } else {
            setViewAssessmentItemsModal(assessmentItems.filter(item => item.knowShowId === knowShow._id.$oid))
        }
    }

    function formatDateToMMDD(date) {
        // Extract the month and day from the date object
        const month = date.getMonth() + 1; // Months are zero-indexed
        const day = date.getDate();

        // Pad single digit months and days with leading zeros
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;

        // Return the formatted date as mm/dd
        return `${formattedMonth}/${formattedDay}`;
    }

    const handleClearOutstandingAssessments = (roster) => {
        if (window.confirm('This will delete all outstanding assignments and keep currently pending students as pending without the option to perform the asseessment. Are you sure?')) {
            setIsLoading(true)
            fetch(`${process.env.REACT_APP_API_BASE}/${process.env.REACT_APP_API_STAGE}/logistics/putClearOutstandingAssessments`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    roster: roster,
                }),

            })
                .then(async (response) => {
                    if (response.ok) {
                        window.alert(`Assignments cleared for ${roster.title}`)
                        setIsLoading(false)
                    }
                })
        }
    }

    if (!user.userContext) {
        return <Loader message={'Fetching user data'}/>
    } else if (!user.userContext.termsAccepted) {
        return <ToSModal user={user}/>
    } else if (user.userContext.role === 'student') {
        navigate('/home')
    } else if (!studentContext || !assessmentItems || !rosters || !knowShowCharts || !unitPlans) {
        return <Loader message={`Fetching: ${(!rosters) ? "Rosters, " : ""}${(!studentContext) ? "Student Data, " : ""}${(!knowShowCharts) ? "Standard Elements, " : ""}${(!assessmentItems) ? "Assessment Items, " : ""}${(!unitPlans) ? "Unit Plans" : ""}`}/>
    }else {
        return (
            <div className={'min-h-screen'}>
                {(user.userContext) && user.userContext.preps.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).map(prep =>
                    <div className={'bg-burnt_sienna-400 m-4 rounded shadow-lg'}>
                        <p className={"font-bold text-columbia_blue-800 text-3xl py-2"}>{prep}</p>
                        <div className={'flex'}>
                            <div className={'w-[80%] flex flex-col m-2'}>
                                <div className={'h-full w-full bg-paynes_gray-200 rounded flex flex-col'}>
                                    {(!assessmentItems) ? <div className="my-12 mx-auto animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-columbia_blue-700" /> :
                                        <div>
                                        {(user.userContext.currentUnits[prep]) && (unitPlans) && (Array.isArray(unitPlans)) && (unitPlans.find(item => item._id.$oid === user.userContext.currentUnits[prep])) && <div className={'font-bold text-lg text-dutch_white-900 py-2'} dangerouslySetInnerHTML={renderHtml(unitPlans.find(item => item._id.$oid === user.userContext.currentUnits[prep]).title)} />}
                                        <div className={'h-[400px] overflow-y-auto py-1 rounded'}>
                                        {(assessmentItems) && (unitPlans.find(item => item._id.$oid === user.userContext.currentUnits[prep])) && (unitPlans && (unitPlans.filter(item => item.prep === prep).length > 0)) ?
                                        <div className={'mx-2 bg-dutch_white-500 pt-2 rounded border-columbia_blue-800 shadow grid grid-cols-5 pb-2 px-1 gap-1'}>
                                            {(user.userContext.currentUnits[prep]) ? unitPlans.find(item => item._id.$oid === user.userContext.currentUnits[prep]).calendar.map((day, index) => <>
                                                        <div
                                                            className={'min-h-[98px] bg-dutch_white-300 mx-1 rounded border-4 border-dutch_white-300 flex flex-col'}>
                                                            <p className={'text-xs text-dutch_white-800 text-start ml-1 select-none'}>Day {index + 1}</p>
                                                            {day.map(element => (assessmentItems.filter(item => item.knowShowId === element._id.$oid).length > 0)
                                                                ? (element.type !== 'Recall') ?
                                                                    <div className={'mb-1'}>
                                                                        <div
                                                                            onClick={() => handleAssignmentClick(element)}
                                                                            className={'text-[12px] rounded-t rounded-bl p-1 select-none bg-columbia_blue-800 cursor-pointer hover:bg-columbia_blue-900 shadow-md text-dutch_white-300'}>{element.text}</div>
                                                                        <div className={'flex'}>
                                                                            <div className={'grow'}/>
                                                                            <div className={'flex flex-col w-[80%]'}>
                                                                                {(rosters && Array.isArray(rosters)) && rosters.filter(item => item.prep === prep).map((item, index, filteredRosters) => (
                                                                                    <RosterAssignmentData
                                                                                        key={item._id.$oid} // Assuming item._id is unique
                                                                                        item={item}
                                                                                        element={element}
                                                                                        isLastItem={index === filteredRosters.length - 1}/>
                                                                                ))}
                                                                            </div>

                                                                        </div>
                                                                    </div> :
                                                                    <div className={'mb-1'}>
                                                                        <div
                                                                            onClick={() => handleAssignmentClick(element)}
                                                                            className={'text-[1rem] rounded-t rounded-bl px-1 py-2 select-none bg-columbia_blue-900 cursor-pointer hover:bg-columbia_blue-800 shadow-md text-dutch_white-400'}>
                                                                            <p className={'font-bold'}>{element.title}</p>
                                                                            <p className={'text-[0.6rem]'}>{element.standardTitle}</p></div>
                                                                        <div className={'flex'}>
                                                                            <div className={'grow'}/>
                                                                            <div className={'flex flex-col w-[80%]'}>
                                                                                {(rosters && Array.isArray(rosters)) && rosters.filter(item => item.prep === prep).map((item, index, filteredRosters) => (
                                                                                    <RosterAssignmentData
                                                                                        key={item._id.$oid} // Assuming item._id is unique
                                                                                        item={item}
                                                                                        element={element}
                                                                                        isLastItem={index === filteredRosters.length - 1}/>
                                                                                ))}
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                : <div
                                                                    className={'text-[12px] rounded p-1 select-none bg-platinum-700 shadow-md text-dutch_white-400 mb-2'}>{(element.type !== 'Recall') ? element.text : element.title}
                                                                </div>
                                                            )}

                                                        </div>
                                                    </>)
                                                    :
                                                    <>
                                                        <div onClick={() => setChangeUnitModal(prep)}
                                                             className={'p-2 bg-columbia_blue-600 rounded cursor-pointer h-fit my-auto shadow hover:bg-columbia_blue-700 col-start-2'}>Choose
                                                            Your Current Unit
                                                        </div>
                                                        <p className={'my-auto'}>or</p>
                                                        <div onClick={() => setCreateUnitModal(prep)}
                                                             className={'p-2 bg-columbia_blue-600 rounded cursor-pointer h-fit my-auto shadow hover:bg-columbia_blue-700'}>Create
                                                            a New Unit
                                                        </div>
                                                    </>}
                                            </div>
                                            : <div onClick={() => setCreateUnitModal(prep)} className={'p-2 bg-columbia_blue-700 hover:bg-columbia_blue-800 rounded cursor-pointer text-dutch_white-300 font-bold h-fit w-fit mx-auto mt-[150px] shadow col-start-3'}>Create New Unit</div>
                                        }
                                        </div>
                                        </div>}

                                {(user.userContext.currentUnits[prep]) &&
                                    <div className={'m-2 flex text-dutch_white-300 font-bold'}>
                                        <div className={'grow'} />
                                        <div onClick={() => setCreateUnitModal(prep)} className={'p-2 w-fit bg-columbia_blue-700 hover:bg-columbia_blue-800 rounded cursor-pointer shadow'}>Create New Unit</div>
                                        <div className={'grow'} />
                                        <div onClick={() => setAdjustUnitModal(unitPlans.find(item => item._id.$oid === user.userContext.currentUnits[prep]))} className={'p-2 w-fit bg-columbia_blue-700 hover:bg-columbia_blue-800 rounded cursor-pointer shadow'}>Adjust This Unit</div>
                                        <div className={'grow'} />
                                        <div onClick={() => setChangeUnitModal(prep)} className={'p-2 w-fit bg-columbia_blue-700 hover:bg-columbia_blue-800 rounded cursor-pointer shadow'}>Select Unit</div>
                                        <div className={'grow'} />
                                    </div>}

                                </div>
                            </div>

                            <div className={'flex flex-col p-4 gap-4 w-[20%] bg-platinum rounded-br rounded-tl bg-opacity-20'}>
                                <p className={'text-columbia_blue-900 -my-2'}>Rosters</p>
                                {(!rosters) ? <div className={'p-8 mx-auto opacity-70'}><div className="border-t-2 border-b-2 border-columbia_blue h-6 w-6 rounded-full animate-spin mx-auto"></div></div> : <>{rosters.filter(item => item.prep === prep).sort((a, b) => a.title.localeCompare(b.title, undefined, { sensitivity: 'base' })).map(roster =>
                                    <div className={'rounded flex flex-col bg-dutch_white-400 p-2 shadow'}>

                                        <p className={'text-columbia_blue-700 font-bold text-xl mb-2 select-none'}>{roster.title}</p>
                                        <div className={'flex gap-2 mx-auto'}>
                                            {(groupData) ? <div className={'p-1 bg-columbia_blue rounded font-bold hover:bg-columbia_blue-600 select-none cursor-pointer shadow h-fit my-auto text-sm text-dutch_white-900'} onClick={() => setStudentDataModal(roster)}><p className={'my-auto'}>Student Data</p></div> : <div className={'p-2 bg-columbia_blue rounded font-bold hover:bg-columbia_blue-600 select-none cursor-pointer shadow'} ><div className="border-t-2 border-b-2 border-burnt_sienna h-6 w-6 rounded-full animate-spin mx-auto"></div></div>}
                                            {(studentContext) ? <div className={'p-1 bg-burnt_sienna rounded font-bold hover:bg-burnt_sienna-600 select-none cursor-pointer shadow h-fit my-auto text-sm text-dutch_white-900'} onClick={() => setEditRosterModal(roster)}><p className={'my-auto'}>Edit Roster</p></div> :  <div className={'p-2 bg-burnt_sienna rounded font-bold select-none cursor-pointer shadow'} ><div className="border-t-2 border-b-2 border-columbia_blue h-6 w-6 rounded-full animate-spin mx-auto"></div></div>}
                                        </div>
                                        <div className={'p-1 mt-2 bg-columbia_blue-800 rounded font-bold hover:bg-columbia_blue-700 select-none cursor-pointer shadow h-fit my-auto text-sm text-dutch_white-300'} onClick={() => setGroupModal(roster)}>Groups</div>
                                    </div>

                                )}
                                    <div className={'flex items-center bg-columbia_blue-700 p-6 hover:bg-columbia_blue-800 h-6 rounded shadow cursor-pointer w-fit mx-auto'} onClick={() => setNewRosterModal(prep)}>
                                        <p className={'font-bold text-4xl text-dutch_white-300 select-none mb-2'}>+</p>
                                    </div>
                                </>}
                            </div>

                        </div>

                    </div>
                )}
                {(editRosterModal) && <EditRosterModal user={user} studentContext={studentContext} editRosterModal={editRosterModal} rosters={rosters} setRosters={setRosters} setEditRosterModal={setEditRosterModal}/>}
                {(newRosterModal) && <NewRosterModal user={user} newRosterModal={newRosterModal} getRosters={fetchRosters} setNewRosterModal={setNewRosterModal}/>}
                {(groupModal) && <GroupModal groupData={groupData} standardSets={standardSets} groupModal={groupModal} setGroupModal={setGroupModal} standardElements={knowShowCharts} user={user}/>}
                {(studentDataModal) && <StudentDataModal groupData={groupData} standardSets={standardSets} studentDataModal={studentDataModal} setStudentDataModal={setStudentDataModal} standardElements={knowShowCharts}/>}
                {(createUnitModal) && (assessmentItems) && (standardSets) && (knowShowCharts) && <CreateUnitModal setUnitPlans={setUnitPlans} user={user} prep={createUnitModal} setCreateUnitModal={setCreateUnitModal} standardSets={standardSets} assessmentItems={assessmentItems} knowShowCharts={knowShowCharts} getUnitPlans={getUnitPlans}/>}
                {(changeUnitModal) && (Object.keys(user).includes('userContext') && <ChangeUnitModal user={user} prep={changeUnitModal} setChangeUnitModal={setChangeUnitModal} unitPlans={unitPlans}/>)}
                {(adjustUnitModal) && (assessmentItems) && (standardSets) && (knowShowCharts) && <AdjustUnitModal setUnitPlans={setUnitPlans} user={user} unit={adjustUnitModal} setAdjustUnitModal={setAdjustUnitModal} standardSets={standardSets} assessmentItems={assessmentItems} knowShowCharts={knowShowCharts} getUnitPlans={getUnitPlans}/>}
                {(viewAssessmentItemsModal) && <ViewAssessmentItemsModal rosters={rosters} resetFunction={fetchRosters} user={user} viewAssessmentItemsModal={viewAssessmentItemsModal} setViewAssessmentItemsModal={setViewAssessmentItemsModal}/>}
                {(assignRecallModal) && <AssignRecallModal user={user} assignRecallModal={assignRecallModal} rosters={rosters} setAssignRecallModal={setAssignRecallModal} />}
                {(isLoading) && <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"><div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-columbia_blue-700"></div></div>}
            </div>
        );
    }}

export default Logistics;